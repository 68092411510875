<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4> {{ editMode ? 'Editar' : 'Aplicar' }} Retención</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <vs-alert style="height: initial" color="primary" class="mt-2" icon-pack="feather" icon="icon-info">
          La retención será aplicada efectivamente <strong>cuando se cargue el pago</strong> del ticket.
        </vs-alert>
        <!-- Ticket -->
        <vs-input
          disabled="disabled"
          label="Ticket"
          v-model="dataId"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/ }"
          name="item-id" />
        <span class="text-danger text-sm" v-show="errors.has('item-id')">{{ errors.first('item-id') }}</span>


        <!-- AMOUNT -->
        <vs-input
          icon-pack="feather"
          icon="icon-dollar-sign"
          label="Monto a retener"
          v-model="dataRetentionAmount"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /^\d*\.?\d*$/, min_value: 1, max_value: dataTotal }"
          data-vv-as="Monto a retener"
          name="item-amount" />
        <span class="text-danger text-sm" v-show="errors.has('item-amount')">{{ errors.first('item-amount') }}</span>

        <vs-textarea
          label="Motivos"
          class="mt-5 w-full"
          v-model="dataDescription"
          name="description"
          v-validate="'required|min:1|max:300'"
          data-vv-as="Motivos"/>
        <span class="text-danger text-sm"  v-show="errors.has('description')">{{ errors.first('description') }}</span>


        <vs-button v-if="editMode" color="danger" class="mt-6 w-full" @click="handleRemoveRetention">Eliminar Retención</vs-button>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Aplicar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';
import DatePicker from "vuejs-datepicker";
import * as lang from 'vuejs-datepicker/src/locale/Language';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return

      this.$validator.reset()
      this.initValues()

      const { id, total_pay, withholdings } = JSON.parse(JSON.stringify(this.data))
      this.dataId = id
      this.dataTotal = total_pay
      this.dataWithholdings = withholdings

      if (this.dataWithholdings.length > 0) {
        this.dataRetentionAmount = this.dataWithholdings[0].amount
        this.dataDescription = this.dataWithholdings[0].description
        this.editMode = true
      }
    }
  },
  data() {
    return {

      dataId: null,
      dataTotal: 0,
      dataWithholdings: [],
      dataRetentionAmount: '',
      dataDescription: '',

      dataPayDate: new Date(),
      dataReceipt: "",
      dataStatus: "",
      dataType: false,
      dataRecharges: false,
      languages: lang,
      isPdf: false,
      file_name: "",

      product_image: {
        size: '',
        width: '',
        height: ''
      },

      image_loaded: false,
      image_error: '',

      status_choices: [],

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },

      editMode: false, //this.data.withholdings.length != 0
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.dataPayDate && this.image_error == ''
    },
  },
  methods: {
    initValues() {
      if(this.data.id || this.data.id_advance_request) return
        this.dataId = null
        this.dataTotal = 0
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          let action = 'addRetention'
          let obj = {
            id_service_payment_request: this.dataId,
            amount: this.dataRetentionAmount,
            description: this.dataDescription,
          }

          if (this.editMode) {
            action = 'editRetention'
            obj.id = this.dataWithholdings[0].id
          }

          this.dispatchAction(action, obj)
        }
      })
    },
    dispatchAction(action, data) {
      this.$store.dispatch(`reportModule/${action}`, data)
        .then(response => {
          if (response.status)
            this.$emit('closeSidebar')
            this.initValues()
            this.$vs.loading.close()
            this.$router.go()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    handleRemoveRetention() {
      this.$store.dispatch(`reportModule/removeRetention`, this.dataWithholdings[0].id)
        .then(response => {
          if (response.status)
            this.$emit('closeSidebar')
            this.initValues()
            this.$vs.loading.close()
            this.$router.go()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();

        if(input.target.files[0]['type'] === 'application/pdf') {
          if(input.target.files[0].size > 2000000) {
            this.image_error = "El archivo excede el tamaño máximo (2 MB)";
            return false;
          }
          reader.onload = e => {
            this.dataReceipt = e.target.result;
            this.image_loaded = true;
            this.file_name = input.target.files[0].name;
            this.isPdf = true;
          }
        } else {
          this.isPdf = false;
          this.product_image.size = input.target.files[0].size;
          reader.onload = e => {
            let img = new Image();
            img.onload = () => {
              this.product_image.width = img.width;
              this.product_image.height = img.height;
              if (this.product_image.width > 3000 ) {
                this.image_error = 'La imágen debe tener un tamaño máximo de 3000px';
              } else {
                if (this.product_image.size > 1000000){
                  this.image_error = 'La imágen excede el tamaño máximo (1 MB).';
                } else {
                  this.dataReceipt = e.target.result
                  this.image_loaded = true;
                }
              }
            }
            img.src = e.target.result;
          }
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
    DatePicker
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
